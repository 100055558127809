<template>
	<div class="root">
		<div v-if="rst" id="top-box" :style="topBoxStyleObj"></div>
		<!-- <van-icon name="arrow-left" id="back-btn" @click="backClick" /> -->
		<div id="centre-box">
			<div class="centre-box-title-h2">
				<div id="centre-box-img">
					<img :src="imgFilter(rst.image_path)" alt />
				</div>
				<span>{{rst.name}}</span>

			</div>
			<div class="centre-box-title-div">
				<img src="../../assets/star.png" style="width: 15px;height: 15px;">
				<span>评价{{rst.rating}}</span>&#x3000;
				<span>月售{{rst.recent_order_num}}单</span>&#x3000;
			</div>
			<div class="centre-box-not">公告:欢迎光临，很高兴为您服务~</div>
			<div class="favourable-right">
				3个优惠
			</div>
			<div class="home-selected">
				<div class="selected-content">
					<div class="selected-item" v-for="item in jingxuanList">
						<p class="item-price">￥<span>{{item.price}}</span></p>
						<div class="time">
							<p>{{item.startTime}}至{{item.endTime}}</p>
							<p>满{{item.detail}}可用</p>
						</div>

						<p class="getCou">领</p>
					</div>
				</div>
			</div>
		</div>

		<van-tabs v-model="active" color="#FC6228">
			<van-tab title="菜单">
				<van-tree-select :height="treeHeight" :items="menu" :main-active-index.sync="active"
					@click-nav="selectedCate">
					<template #content>
						<div class="goods">
							<!-- <p class="classify-title">{{menu[active].text}}</p> -->
							<div class="goods-list">
								<div class="goods-item" v-for="goods in menu[active].foods">

									<div class="goods-top">
										<img class="goods-image" :src="goods.image_path" alt="">
										<div class="goods-content">
											<p class="goods-name">{{goods.name}}</p>
											<span class="goods-price">￥{{goods.price}}</span>
										</div>
									</div>
									<div class="goods-stepper">
										<van-stepper v-model="goods.num" theme="round" button-size="22" disable-input />
									</div>
								</div>
							</div>
						</div>
					</template>
				</van-tree-select>
				<div class="shop-detail-bottom">
					<div class="shopping-cart">
						<van-badge :content="5">
							<div style="width: 20px;height: 20px;position: relative;">
								<van-icon name="shopping-cart" size="20" color="#FC6228" style="position: absolute;"
									@click="showPopup"/>
							</div>
						</van-badge>
						
						<span class="shopping-price">￥10</span>
					</div>
					<div class="shopping-btn" @click="placeOrder()">
						<span>下单</span>
					</div>
				</div>
				<van-popup v-model:show="show"  position="bottom" :style="{ height: '45%' }">
					<div class="topShop">
						<p>已选商品(2)</p>
						<div class="empty">
							<van-icon name="delete-o" />
							<p>清空</p>
						</div>
					</div>
					<div v-for="(item,index) in selectedList" class="selectedList">
						<div class="leftSelect">
							<img :src="item.image_path" alt="" style="width: 74px;height: 74px;border-radius: 3px;">
							<div>
								<p>{{item.name}}</p>
								<p>￥{{item.price}}</p>
							</div>
						</div>
						<div class="rightSelect">
							<van-stepper v-model="item.count" theme="round" button-size="22" disable-input />
						</div>
					</div>
				</van-popup>
			</van-tab>
			<van-tab title="评价">
				<!-- <van-cell is-link >展示弹出层</van-cell> -->
				<div class="comment">
					<ul class="mealList">
						<li v-for="(v,k) in comment.list" @click="activeClick(k)" :class="{active:index == k}">
							{{v.name}}({{v.count}})</li>
					</ul>
					<div v-for="(item,index) in comment.peoList" class="characters">
						<div class="topHeader">
							<div class="topHeader1">
								<img :src="item.portrait" alt="" style="width: 60px;height: 60px;border-radius: 50%;">
								<div class="topHeader11">
									<p>{{item.name}}</p>
									<van-rate v-model="item.star" allow-half color="#FC6228" :size="15" />
								</div>
							</div>
							<div class="topHeader2">{{item.time}}</div>
						</div>
						<div class="detail">
							{{item.detail}}
						</div>
						<div class="imageList">
							<img :src="item.portrait" alt="" v-for="(item,index) in item.image">
						</div>
					</div>
				</div>
			</van-tab>
			<!-- <van-tab title="商家">商家</van-tab> -->
		</van-tabs>

	</div>
</template>


<script>
	import axios from "axios"
	import imgFilter from "../../utils/imgFilter.js";
	// import {
	// 	shopList
	// } from "../../api/index.js"
	import NumCtrl from "../../components/NumCtrl.vue";
	import { ref } from 'vue';

	export default {
		components: {
			NumCtrl
		},
		filters: {},
		 setup() {
		    const show = ref(false);
		    const showPopup = () => {
		      show.value = true;
		    };
		    return {
		      show,
		      showPopup,
		    };
		  },
		data() {
			return {
				index: 0,
				value: 4.9,
				active: 0,
				jingxuanList: [{
						price: 5,
						startTime: '8月9',
						endTime: '9月8',
						detail: 10
					},
					{
						price: 5,
						startTime: '8月9',
						endTime: '9月8',
						detail: 10
					},
					{
						price: 5,
						startTime: '8月9',
						endTime: '9月8',
						detail: 10
					},
				],
				rst: {
					name: "饭先生.锡纸饭",
					image_path: "64ab5580b206f2cc298d1ba116423877jpeg",
					rating: 4.5,
					recent_order_num: 300,
					shop_sign: {
						brand_story: "",
						image_hash: "801a96348005148874a25bfe7ac0b164png"
					},
				},
				menu: [{
						text: "打包类",
						badge: 5,
						foods: [{
							name: "打包盒",
							// description: "\n主要原料：蒲公英",
							month_sales: 49,
							satisfy_rate: 88.0,
							price: "0.01",
							image_path: "https://img30.360buyimg.com/babel/s320x320_jfs/t1/97106/19/425/75711/5dad6b79E0cf2e2d0/87113e1d15f42def.jpg!cc_320x320.webp",
							num: 0
						}]
					},
					{
						text: "1111",
						badge: 2,
						foods: [{
							name: "1111",
							// description: "\n主要原料：蒲公英",
							month_sales: 49,
							satisfy_rate: 88.0,
							price: "0.01",
							image_path: "https://img11.360buyimg.com/babel/s320x320_jfs/t1/181831/2/9963/164337/60ccc643Ea7b084db/7de998eb17162515.jpg!cc_320x320.webp",
							num: 0
						}]
					},
					{
						text: "2222",
						foods: [{
							name: "2222",
							// description: "\n主要原料：蒲公英",
							month_sales: 49,
							satisfy_rate: 88.0,
							price: "0.01",
							image_path: "https://img13.360buyimg.com/babel/s320x320_jfs/t1/10616/13/5546/328025/5c170510E7a4ee8ac/58fa708d5ca7b30f.jpg!cc_320x320.webp",
							num: 0
						}]
					},
					{
						text: "333",
						foods: [{
							name: "333",
							// description: "\n主要原料：蒲公英",
							month_sales: 49,
							satisfy_rate: 88.0,
							price: "2.00",
							image_path: "https://img14.360buyimg.com/babel/s320x320_jfs/t1/188648/19/8970/168327/60cb7f46E019b2d13/c5cde182255f60b6.jpg!cc_320x320.webp",
							num: 0
						}]
					},
					{
						text: "444",
						foods: [{
								name: "香444肠",
								// description: "\n主要原料：蒲公英",
								month_sales: 49,
								satisfy_rate: 88.0,
								price: "2.00",
								image_path: "https://img11.360buyimg.com/babel/s320x320_jfs/t1/155915/26/17743/184611/605a8c86E2689ee88/76ced093cb2477e2.jpg!cc_320x320.webp",
								num: 0
							},
							{
								name: "44444",
								// description: "\n主要原料：蒲公英",
								month_sales: 49,
								satisfy_rate: 88.0,
								price: "2.00",
								image_path: "https://img12.360buyimg.com/babel/s320x320_jfs/t1/139557/15/7475/144947/5f521860E300f242b/378d6b46d2e32171.jpg!cc_320x320.webp",
								num: 0
							}, {
								name: "香4444肠",
								// description: "\n主要原料：蒲公英",
								month_sales: 49,
								satisfy_rate: 88.0,
								price: "2.00",
								image_path: "https://img10.360buyimg.com/babel/s320x320_jfs/t1/198502/27/1853/80514/610c9c33E2d71a143/390704c7598218a4.jpg!cc_320x320.webp",
								num: 0
							}, {
								name: "444444",
								// description: "\n主要原料：蒲公英",
								month_sales: 49,
								satisfy_rate: 88.0,
								price: "2.00",
								image_path: "https://img13.360buyimg.com/babel/s320x320_jfs/t1/190561/8/17993/135476/6114b4e6Ed15d676d/b9d8c427c2f5dea5.jpg!cc_320x320.webp",
								num: 0
							},
						]
					},
					{
						text: "6666",
						foods: [{
								name: "6666",
								// description: "\n主要原料：蒲公英",
								month_sales: 49,
								satisfy_rate: 88.0,
								price: "2.00",
								image_path: "https://img13.360buyimg.com/babel/s320x320_jfs/t1/196128/4/17823/486291/6114d960E49a1c7ed/b5fd8419a9220902.jpg!cc_320x320.webp",
								num: 0
							}, {
								name: "6666666",
								// description: "\n主要原料：蒲公英",
								month_sales: 49,
								satisfy_rate: 88.0,
								price: "2.00",
								image_path: "https://img20.360buyimg.com/babel/s320x320_jfs/t1/125700/38/2425/262731/5ec5e2fbEd0323e57/b1ce3d52530b9eed.jpg!cc_320x320.webp",
								num: 0
							},
							{
								name: "6666666666",
								// description: "\n主要原料：蒲公英",
								month_sales: 49,
								satisfy_rate: 88.0,
								price: "2.00",
								image_path: "https://img14.360buyimg.com/babel/s320x320_jfs/t1/202714/29/1039/494493/6114e3b3E0e97a4e4/a277bc56a69c8504.jpg!cc_320x320.webp",
								num: 0
							}
						]
					},
				],
				// cart:[],

				cateBoxTop: [],
				isAnimation: false,
				treeHeight: 0,
				comment: {
					list: [{
							name: '全部',
							count: 1898
						},
						{
							name: '好评',
							count: 998
						},
						{
							name: '差评',
							count: 98
						},
						{
							name: '推荐',
							count: 786
						},
						{
							name: '包装好',
							count: 86
						},
						{
							name: '味道赞',
							count: 78
						}
					],
					peoList: [{
							name: "super_man",
							time: "2021.08.14",
							star: 5,
							portrait: 'https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg',
							detail: "好吃，美味，好吃，，美味，好吃，，美味，好吃，，美味，好吃，，美味，好吃，，美味，好吃，，美味，好吃，，美味，好吃，，美味，好吃，，美味，好吃",
							image: [{
									portrait: 'https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg',
								},
								{
									portrait: 'https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg',
								},
								{
									portrait: 'https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg',
								},
							]
						},
						{
							name: "super_man",
							time: "2021.08.14",
							star: 5,
							portrait: 'https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg',
							detail: "好吃，美味，好吃，，美味，好吃，，美味，好吃，，美味，好吃，，美味，好吃，，美味，好吃，，美味，好吃，，美味，好吃，，美味，好吃，，美味，好吃",
							image: [{
									portrait: 'https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg',
								},
								{
									portrait: 'https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg',
								},
								{
									portrait: 'https://img0.baidu.com/it/u=4057413083,243452144&fm=26&fmt=auto&gp=0.jpg',
								},
							]
						}
					]
				},
				selectedList:[
					{
						image_path: "https://img30.360buyimg.com/babel/s320x320_jfs/t1/97106/19/425/75711/5dad6b79E0cf2e2d0/87113e1d15f42def.jpg!cc_320x320.webp",
						name:"旺仔牛奶",
						count:3,
						price:89
					},
					{
						image_path: "https://img30.360buyimg.com/babel/s320x320_jfs/t1/97106/19/425/75711/5dad6b79E0cf2e2d0/87113e1d15f42def.jpg!cc_320x320.webp",
						name:"旺仔牛奶",
						count:3,
						price:89
					}
				]
			};
		},

		mounted() {
			var h = document.body.clientHeight

			this.treeHeight = h - 370
			this.menu.forEach(e1 => {
				e1.foods.forEach(e2 => {
					console.log(e2.num)
					e2.num = 0;
				});
			});
			// this.$nextTick(function() {
			// 	console.log(this.$refs.cates)
			// })
		},
		// updated() {
		// 	this.calcHeight();
		// },

		computed: {
			topBoxStyleObj() {
				return {
					"background-image": `url(${imgFilter(
          this.rst.shop_sign.image_hash,
          750,
          200
        )})`
				};
			}
		},

		methods: {
			placeOrder() {
				this.$router.push({
					path: '/h5/onceOeder'
				})
			},
			activeClick(k) {
				console.log(k)
				this.index = k;
			},
			selectedCate(i) {

				this.active = i
			},
			backClick() {
				this.$router.back();
			},
			imgFilter,
			cateClick(i) {
				this.selectedCateIndex = i;
				console.log(i);
				console.log(this.$refs.rightBox)
				console.log(this.$refs.cates)

				// // 不带动画
				// this.$refs.rightBox.scrollTop = this.cateBoxTop[i];
				// // scrollTo动画效果
				// this.$refs.rightBox.scrollTo({
				//     top:this.cateBoxTop[i],
				//     left:0,
				//     behavior:"smooth"
				// });

				// js动画
				let from = this.$refs.rightBox.scrollTop;
				let to = this.cateBoxTop[i];
				let distance = to - from;
				console.log(from, to)
				// 速度，像素/帧
				let v = distance / 20;

				let update = () => {
					this.$refs.rightBox.scrollTop += v;

					if (Math.abs(this.$refs.rightBox.scrollTop - to) <= Math.abs(v)) {
						this.$refs.rightBox.scrollTop = to;
						this.isAnimation = false;
					} else {
						requestAnimationFrame(update);
					}
				};
				this.isAnimation = true;
				update();
			},

			calcHeight() {
				console.log(this.$refs.cates[0]);
				this.cateBoxTop = this.$refs.cates.map(e => {
					return e.offsetTop;
				});
			},

			rightScroll() {
				if (this.isAnimation) {
					return;
				}
				let st = this.$refs.rightBox.scrollTop;
				// console.log(st,this.cateBoxTop)
				let index = null;
				for (let i = 0; i < this.cateBoxTop.length; i++) {
					if (this.cateBoxTop[i] > st) {
						index = i - 1;
						break;
					}
				}

				this.selectedCateIndex = index;
			}
		}
	};

	// [0,150,400,500,750]
</script>


<style lang="less" scoped>
	.active {
		color: #FC6228;
		border: solid 1px #FC6228;
		padding: 2px 5px;
		border-radius: 5px;
	}

	.comment {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		font-size: 14px;

		ul {
			width: 90%;
			display: flex;
			align-items: center;
			flex-wrap: wrap;

			// justify-content: space-between;
			li {
				padding: 2px 5px;
				margin-left: 5px;
				border: solid 1px #ccc;
				margin-bottom: 5px;
			}
		}

		.characters {
			width: 90%;
			font-size: 0.2rem;
			margin-bottom: 15px;

			.topHeader {
				display: flex;
				justify-content: space-between;
				align-items: center;

				.topHeader1 {
					display: flex;
					align-items: center;

					img {}

					.topHeader11 {
						margin-left: 8px;
					}
				}

				.topHeader2 {
					color: #999999;
				}
			}

			.detail {
				color: #333333;
				margin: 10px 0;
			}

			.imageList {
				width: 100%;
				// height: 70px;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				img {
					width: 110px;
					height: 110px;
					border-radius: 10px;
				}
			}
		}
	}

	p {
		margin: 0;
		padding: 0;
	}

	::v-deep .van-tab {
		margin-left: 10px;
	}

	::v-deep .van-tab__text {
		font-size: 16px;

		font-weight: bold;

	}

	::v-deep .van-tabs__nav {
		background-color: #f6f6f6;
	}

	::v-deep .van-tabs__line {
		width: 20px;
		background-color: #FB6228;
		bottom: 22px;


	}

	.shop-detail-bottom {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 55px;
		background-color: #FFFFFF;
		padding: 5px 15px;
		box-sizing: border-box;
		align-items: center;
		display: flex;
		justify-content: space-between;
		z-index: 2005;
		.shopping-cart {
			display: flex;
			align-items: center;

			.shopping-price {
				font-size: 16px;
				color: #1A1A1A;
				margin-left: 5px;
			}
		}

		.shopping-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 18px;
			color: #FFF6F3;
			width: 94px;
			height: 37px;
			font-size: 17px;
			background-color: #FC6228;
		}
	}
	.topShop{
		font-size: 14px;
		display: flex;
		justify-content: space-between;
		padding: 10px;
		.empty{
			display: flex;
			align-items: center;
		}
	}
	.selectedList{
		font-size: 14px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 10px;
		.leftSelect{
			display: flex;
			align-items: center;
		}
		.rightSelect{
			::v-deep .van-stepper__plus {
				background-color: #FC6228;
			}
			
			::v-deep .van-stepper__minus {
				color: #FC6228;
				border-color: #FC6228;
			}
		}
	}

	.goods {
		width: 100%;
		padding: 0 10px;
		box-sizing: border-box;

		.classify-title {
			font-size: 14px;
			color: #999999;
		}

		.goods-list {
			display: flex;
			flex-direction: column;

			.goods-item {
				display: flex;
				flex-direction: column;
				border-bottom: solid 1px #F0F0F0;
				padding: 8px 0;
				box-sizing: border-box;

				width: 100%;

				.goods-stepper {
					display: flex;
					justify-content: flex-end;

					::v-deep .van-stepper__plus {
						background-color: #FC6228;
					}

					::v-deep .van-stepper__minus {
						color: #FC6228;
						border-color: #FC6228;
					}
				}

				.goods-top {
					display: flex;


					.goods-image {
						width: 80px;
						height: 80px;
						border-radius: 3px;
					}

					.goods-content {
						display: flex;
						flex-direction: column;
						margin-left: 10px;

						.goods-name {
							color: #1A1A1A;
							font-size: 14px;
						}

						.goods-price {
							color: #FC6228;
							font-size: 17px;
						}
					}
				}

			}
		}
	}

	::v-deep .van-sidebar {
		width: 89px;
		flex: none;

		.van-sidebar-item--select::before {
			position: absolute;
			top: 50%;
			left: 0;
			width: 0;
			height: 0;
			background-color: #ee0a24;
			-webkit-transform: translateY(-50%);
			transform: translateY(-50%);
			content: '';
		}
	}

	.home-selected {
		// background-color: #FFEFCD;
		padding-bottom: 10px;
		width: 100%;
		border-radius: 10px;
		margin-top: 10px;

		.selected-title {
			display: flex;
			width: 100%;
			justify-content: space-between;
			font-size: 16px;
			position: relative;

			span {
				padding: 5px 10px;
				font-size: 14px;
				font-weight: bold;
				line-height: 20px;

			}

			.title-right {
				background-color: #FFFCF5;
				margin: 0;
				padding: 5px 10px;
				width: 65%;
				position: absolute;
				right: 0;
				font-size: 10px;
				color: #5A4420;
				line-height: 20px;
				border-top-right-radius: 10px;
			}

			.title-right::after {
				content: ' ';
				position: absolute;
				top: 0;
				left: -8px;
				border-width: 30px 8px;
				border-style: solid;
				border-color: #FFFCF5 transparent transparent transparent;
			}
		}

		.selected-content {
			display: flex;
			width: 100%;
			margin-top: 5px;
			overflow-x: auto;
			justify-content: space-between;

			.selected-item::before {
				content: '';
				height: 100px;
				border: 1px dashed white;
				position: absolute;
				left: 80%;
				top: 0;
				bottom: 0;
				margin: auto -2px;
			}

			.selected-item {
				width: 136px;
				height: 37px;
				display: flex;
				flex-shrink: 0;
				align-items: center;
				justify-content: space-between;
				// flex-direction: column;
				margin-left: 5px;
				background-color: #ffffff;
				border-radius: 5px;
				padding: 3px 8px;
				box-sizing: border-box;
				background: radial-gradient(circle at right top, transparent 5px, #FF6048 0%, #FE8048 100%) top left / 80% 51% no-repeat,
					radial-gradient(circle at right bottom, transparent 5px, #FF6048 0%, #FE8048 100%) bottom left /80% 51% no-repeat,
					radial-gradient(circle at left top, transparent 5px, #FF6048 0%) top right /20% 51% no-repeat,
					radial-gradient(circle at left bottom, transparent 5px, #FF6048 0%) bottom right /20% 51% no-repeat;
				filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, .3));
				color: #FFFFFF;

				p {
					margin: 0;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					word-break: break-all;
				}

				.item-price {
					font-size: 17px;
				}

				.time {
					font-size: 10px;
					width: 1rem;
				}

				.line {
					// width: 2px;
					height: 100%;
					border: dotted 1px #FFFFFF;
				}

				.getCou {
					font-size: 10px;
				}
			}
		}
	}

	::v-deep .van-tab {
		flex: none;
	}

	.root {
		position: relative;
	}

	#top-box {
		height: 100px;
		background-size: 100% 120%;
		background-position: center center;
		filter: brightness(0.8);
	}

	#centre-box {
		padding: 0 10px;
		position: relative;
		display: flex;
		flex-direction: column;
		/* align-items: center; */
		background-color: #fff;
	}

	#centre-box-img {
		width: 46px;
		height: 46px;
		position: relative;
		top: -10px;
		margin-right: 15px;

	}

	#centre-box-img img {
		width: 100%;
		height: 100%;

	}



	.centre-box-title-h2 {
		font-size: 19px;
		font-weight: 700;
		white-space: nowrap;
		position: relative;

		display: flex;
		align-items: center;
		// justify-content: center;
		margin: 0;
	}

	.centre-box-title-div {
		white-space: nowrap;
		display: flex;
		align-items: center;
		margin-top: 10px;
		font-size: 12px;
		color: #666;

		/* text-align: center; */
	}

	.centre-box-not {
		font-size: 12px;
		color: #666;

		margin-top: 10px;
	}

	.center-box-yellow {
		display: flex;
		justify-content: center;
		margin: 2.133333vw auto 0;
		width: 80vw;
	}





	.favourable-right {
		flex-shrink: 0;
		position: relative;

		text-align: right;
		color: #999;
		font-size: 10px;
	}


	#back-btn {
		position: absolute;
		font-size: 30px;
		color: white;
		left: 10px;
		top: 10px;
	}


	.num-ctrl-box {
		margin-right: 10px;
	}

	.cate-title {
		display: flex;
		align-items: center;
		padding: 8px 10px;
	}
</style>
